import React from 'react'
import ReactDOM from 'react-dom'

import LayoutNearshoring from 'components/Layout/LayoutNearshoring'

import './src/styles/global.css'
import './static/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useLayoutData} from './src/hooks/useLayoutData'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const shouldUpdateScroll = ({
  routerProps: {location},
  getSavedScrollPosition,
}) => {
  // List of pathnames where scrolling should be disabled
  const disableScrollPaths = ['/en/en/talents/']

  // Check if the current pathname is in the list of disable scroll paths
  if (disableScrollPaths.some(path => location.pathname.startsWith(path))) {
    return false
  }

  // Default scroll behavior
  const currentPosition = getSavedScrollPosition(location)

  window.scrollTo(...(currentPosition || [0, 0]))

  return true
}

export const wrapPageElement = ({element, props}) => {
  const {pageResources} = props
  const is404 = pageResources.page.path.includes('404')

  const pageIsSSr = pageResources.page.path.includes('ssr')

  const Wrapper = () => {
    const layoutData = useLayoutData()

    return (
      <LayoutNearshoring is404={is404} layoutData={layoutData} {...props}>
        {element}
      </LayoutNearshoring>
    )
  }

  return pageIsSSr ? <div>{element}</div> : <Wrapper />
}
