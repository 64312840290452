exports.components = {
  "component---gatsby-shared-ui-keyrus-nearshoring-templates-page-js": () => import("./../../../../gatsby-shared-ui/keyrusNearshoringTemplates/page.js" /* webpackChunkName: "component---gatsby-shared-ui-keyrus-nearshoring-templates-page-js" */),
  "component---gatsby-shared-ui-keyrus-nearshoring-templates-playbook-js": () => import("./../../../../gatsby-shared-ui/keyrusNearshoringTemplates/Playbook.js" /* webpackChunkName: "component---gatsby-shared-ui-keyrus-nearshoring-templates-playbook-js" */),
  "component---gatsby-shared-ui-keyrus-nearshoring-templates-talents-js": () => import("./../../../../gatsby-shared-ui/keyrusNearshoringTemplates/talents.js" /* webpackChunkName: "component---gatsby-shared-ui-keyrus-nearshoring-templates-talents-js" */),
  "component---gatsby-shared-ui-templates-404-js": () => import("./../../../../gatsby-shared-ui/templates/404.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-404-js" */),
  "component---gatsby-shared-ui-templates-awards-js": () => import("./../../../../gatsby-shared-ui/templates/Awards.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-awards-js" */),
  "component---gatsby-shared-ui-templates-contact-us-js": () => import("./../../../../gatsby-shared-ui/templates/contactUs.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-contact-us-js" */),
  "component---gatsby-shared-ui-templates-corporate-governance-js": () => import("./../../../../gatsby-shared-ui/templates/corporateGovernance.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-corporate-governance-js" */),
  "component---gatsby-shared-ui-templates-download-page-js": () => import("./../../../../gatsby-shared-ui/templates/downloadPage.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-download-page-js" */),
  "component---gatsby-shared-ui-templates-ethics-compliance-js": () => import("./../../../../gatsby-shared-ui/templates/ethicsCompliance.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-ethics-compliance-js" */),
  "component---gatsby-shared-ui-templates-insight-js": () => import("./../../../../gatsby-shared-ui/templates/Insight.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-insight-js" */),
  "component---gatsby-shared-ui-templates-insights-js": () => import("./../../../../gatsby-shared-ui/templates/Insights.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-insights-js" */),
  "component---gatsby-shared-ui-templates-investors-js": () => import("./../../../../gatsby-shared-ui/templates/investors.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-investors-js" */),
  "component---gatsby-shared-ui-templates-key-play-js": () => import("./../../../../gatsby-shared-ui/templates/KeyPlay.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-key-play-js" */),
  "component---gatsby-shared-ui-templates-locations-js": () => import("./../../../../gatsby-shared-ui/templates/locations.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-locations-js" */),
  "component---gatsby-shared-ui-templates-simple-page-js": () => import("./../../../../gatsby-shared-ui/templates/simplePage.js" /* webpackChunkName: "component---gatsby-shared-ui-templates-simple-page-js" */)
}

