import * as R from 'ramda'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import classNames from 'classnames'
import MenuItem from '@mui/material/MenuItem'
import React, {useState} from 'react'
import Select from '@mui/material/Select'

import {dynamicRouting, removeDuplicate} from 'helpers/utils'
import {footerPropTypes} from 'helpers/propTypes'
import CollapsableMenu from 'components/Layout/CollapsableMenu'
import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import Social from 'components/UI/Social'
import UiLink from 'components/UI/LinkKm'
import useIsMobile from 'hooks/useIsMobile'

import RoundButton from '../../UI/RoundButton'
import useStyles from './styles'

const FooterRoot = ({pageContext, countryData, data}) => {
  const {nodeLocale, prefix, hasShortPath} = pageContext
  const {countryCode, navLocale, technicalName, urlPrefix} = countryData

  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const isMobile = useIsMobile()

  const [currentLocation, setCurrentLocation] = useState(0)

  const handleChange = e => {
    const {value} = e.target

    setCurrentLocation(value)
  }

  const localizedFooter = data.allContentfulFooter.edges.filter(
    el => el.node.node_locale === nodeLocale,
  )

  const loc = R.filter(
    el => el.node.node_locale === nodeLocale,
    R.pathOr(null, ['allContentfulLocations', 'edges'], data),
  )

  const countryLoc = removeDuplicate(
    R.pipe(
      R.pathOr([], [0, 'node', 'locations']),
      R.chain(location =>
        R.map(country => ({...country, location}), location.country),
      ),
      R.filter(country => country.technicalName === technicalName),
      R.map(R.prop('location')),
      R.filter(Boolean),
      R.uniqBy(R.prop('technicalName')),
    )(loc),
  )

  const {
    logo,
    socialMediaLinks,
    locations,
    firstFooterMenu,
    secondFooterMenu,
    thirdFooterMenu,
    bottomMenu,
  } = R.pathOr(null, [0, 'node'], localizedFooter)

  const menuItemsLists = R.reject(R.isNil, [
    firstFooterMenu,
    secondFooterMenu,
    thirdFooterMenu,
  ])

  return (
    <footer className={`${classes.container} sideWrapper`} id="page-footer">
      <div className={classes.menu}>
        <div className={classes.top}>
          <div className={classes.leftSide}>
            <div className={classes.leftSideTop}>
              <div className={classes.logoKeyrus}>
                <Media hasNoStyles data={logo} />
              </div>
              <div className={classes.formControl}>
                <Select
                  MenuProps={{disableScrollLock: true}}
                  value={currentLocation}
                  onChange={handleChange}
                  variant="standard"
                  disableUnderline
                  IconComponent={() => (
                    <ArrowDownIcon className={classes.selectIcon} />
                  )}
                  classes={{
                    outlined: classes.toto,
                    root: classes.outlinedInput,
                    focused: classes.outlinedInputFocused,
                  }}
                >
                  {mapIndexed((node, index) => {
                    const {id, name} = node

                    return (
                      <MenuItem key={`${id}---${index}`} value={index}>
                        <div>{name}</div>
                      </MenuItem>
                    )
                  }, countryLoc)}
                </Select>
              </div>
              <div className={classes.infos}>
                <Description>
                  {R.pathOr(
                    '',
                    ['address', 'address'],
                    countryLoc[currentLocation],
                  )}
                </Description>
                {R.pathOr(null, ['phone'], countryLoc[currentLocation]) && (
                  <Description>
                    {locations.phoneLabel}
                    {countryLoc[currentLocation].phone}
                  </Description>
                )}
                {R.pathOr(null, ['fax'], countryLoc[currentLocation]) && (
                  <Description>
                    {locations.faxLabel}
                    {countryLoc[currentLocation].fax}
                  </Description>
                )}
              </div>
            </div>
            <div className={classes.socialIcons}>
              <Social
                socialMediaLinks={socialMediaLinks}
                hasMarginTop={isMobile}
              />
            </div>
          </div>
          <nav
            className={classNames(classes.rightSide, {
              [classes.spaceEvenly]: R.length(menuItemsLists) < 3,
            })}
          >
            {mapIndexed((node, index) => {
              const isOtherMenu = R.equals(node.name, 'Others')
              const contactUsLink =
                isOtherMenu &&
                (R.equals(countryCode, 'worldwide')
                  ? `/${countryCode}/${node.menuItems[0].link}`
                  : `/${countryCode}/${navLocale}/${node.menuItems[0].link}`)

              return (
                <>
                  {!isOtherMenu ? (
                    <CollapsableMenu
                      node={node}
                      id={`node.id${index}`}
                      prefix={prefix}
                      country={technicalName}
                      countryCode={countryCode}
                      hasShortPath={hasShortPath}
                      navLocale={navLocale}
                    />
                  ) : (
                    <div className={classes.contactUsWrapper}>
                      <RoundButton
                        color="primary"
                        centered
                        isSmallText
                        href={contactUsLink}
                        isNotBold
                      >
                        {node.menuItems[0].title}
                      </RoundButton>
                    </div>
                  )}
                </>
              )
            }, menuItemsLists)}
          </nav>
        </div>

        <div className={classes.bottomSide}>
          {R.map(
            ({
              link,
              id,
              title,
              isExternalLink,
              isOpenInNewWindow,
              isSameMenuItemForAllCountries,
              menuItemPerCountry,
            }) => (
              <div className={`${classes.navigationItemGrey} ${classes.legal}`}>
                <UiLink
                  path={
                    link === 'data-protection'
                      ? dynamicRouting(
                          prefix,
                          `about-us/${link}`,
                          countryCode,
                          hasShortPath,
                          navLocale,
                        )
                      : dynamicRouting(
                          prefix,
                          link,
                          countryCode,
                          hasShortPath,
                          navLocale,
                        )
                  }
                  key={id}
                  label={title}
                  isExternalLink={isExternalLink}
                  isOpenInNewWindow={isOpenInNewWindow}
                  isSameMenuItemForAllCountries={isSameMenuItemForAllCountries}
                  menuItemPerCountry={menuItemPerCountry}
                  country={technicalName}
                />
              </div>
            ),
            bottomMenu.menuItems,
          )}
        </div>
      </div>
    </footer>
  )
}

FooterRoot.propTypes = footerPropTypes.isRequired

export default FooterRoot
